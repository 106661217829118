import * as React from 'react';
import { Flex } from '@westhechiang/flex';
import { StatisticsBlock } from '../../../StatisticsBlock';
import { PinkHeading } from '../../../../../../Headings';

export const HomePageStatisticsRight = () => (
  <Flex
    width={[1, 1, 0.4]}
    ml={[0, 0, 121]}
    mr={[0, 5, 0]}
    mt={[5, 5, 0]}
    flexDirection="column"
    position="relative"
    px={[30, 3, 0]}
  >
    <StatisticsBlock
      isFirst
      percentage="70"
      subHeading="of women believe cannabis consumption carries a stigma"
      preHeading="Women are mostly consuming for therapeutic reasons, but..."
      text=""
    />
    <StatisticsBlock
      percentage="66"
      subHeading="of them hide their usage"
      color="magenta"
      borderTop={0}
      mt={0}
    />
    <StatisticsBlock
      percentage="75"
      subHeading="of female consumers say they lack a trusted online resource"
      preHeading="Women want better"
      text="and"
      textProps={{
        mb: 0,
      }}
      borderTop={1}
    />
    <StatisticsBlock
      color="magenta"
      percentage="68"
      subHeading="of female consumers in recreational states want an improved cannabis retail experience"
      mt={0}
    />
  </Flex>
);
