import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Heading } from '../Headings/Heading/Heading';
import Page from '../Page/Page';
import { P } from '../P/P';
import { HomePageBlock3 } from './HomePage/components/HomePageBlock3';
import { BridgeThatGapBlock } from './HomePage/components/BridgeThatGapBlock';
import { HomePageBlock4 } from './HomePage/components/HomePageBlock4';
import { WhatDoWomenWantSection } from './HomePage/components/WhatDoWomenWantSection';
import { SubscribeBlock } from './HomePage/components/HomePageBlock5/components/SubscribeBlock';
import { RoundedImg } from '../RoundedImg';

export const CannabisPage = () => {
  const { image } = useStaticQuery(graphql`
    query HomePageBlock5Query {
      image: file(relativePath: { eq: "sitting-around-rugs.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 804, maxHeight: 456) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Page>
      <Flex justifyContent="center" width={['100%']} maxWidth={1440}>
        <Flex width={['100%']} flexDirection="column">
          {/* Center Column - All */}
          <Flex
            width={'100%'}
            mt={[5, 150, 148]}
            alignItems="center"
            flexDirection="column"
            px={4}
          >
            <Heading fontFamily="logo" fontSize={12} color="darkGray" mb={4}>
              Cannabis
            </Heading>

            <P>
              We believe in building better industries that value and reflect
              its consumer needs. This starts with understanding that there is
              no “one” cannabis consumer, especially not THE ONE stoner
              stereotype that we’re all familiar with and embracing all current
              & potential cannabis users across age, gender, and race. We
              believe that understanding your audience leads to better products,
              experiences and creates meaningful connections with your
              customers. We are passionate about this industry because cannabis
              helps people. It’s as simple as that.
            </P>
          </Flex>
          {/* End center content */}
          <HomePageBlock3 />
          <BridgeThatGapBlock />
          <HomePageBlock4 />
          <WhatDoWomenWantSection />
          <Flex flex={1} justifyContent={['center']}>
            <SubscribeBlock />
            <Box width={[1, 1, 804]} pl={[3, 0]} height={[400]}>
              <RoundedImg
                height={[400]}
                fluid={image.childImageSharp.fluid}
                borderTopLeftRadius="25px"
                borderBottomLeftRadius="25px"
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Page>
  );
};
