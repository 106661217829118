import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Flex } from '@westhechiang/flex';
import { Box } from '@westhechiang/box';
import { RoundedImg } from '../../../../RoundedImg';

export const HomePageCollage1 = () => {
  const {
    collageImage1,
    collageImage2,
    collageImage3,
  } = useStaticQuery(graphql`
    query HomePageCollage1Query {
      collageImage1: file(relativePath: { eq: "passing-j-by-window.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 456, maxHeight: 564) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      collageImage2: file(
        relativePath: { eq: "woman-looking-at-dropper.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 372) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      collageImage3: file(relativePath: { eq: "cheers-straw-hat.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 325, maxHeight: 744) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Flex
      width={1}
      flexDirection={['row', 'column', 'row']}
      pb={[3, 3, 0]}
      pt={[3, 3, 0]}
    >
      <Flex width={1} flexDirection="column">
        <Box width={[1, 1, 456]} pl={[3, 0]}>
          <RoundedImg
            fluid={collageImage1.childImageSharp.fluid}
            borderBottomLeftRadius={['25px']}
            borderTopRightRadius="25px"
            borderTopLeftRadius={['25px']}
          />
        </Box>
        <Flex
          mt={24}
          width={1}
          alignItems="flex-end"
          flexDirection="column"
          pl={[5, 0]}
        >
          <Box width={[1, 1, 360]}>
            <RoundedImg
              fluid={collageImage2.childImageSharp.fluid}
              borderTopLeftRadius="25px"
            />
          </Box>
        </Flex>
      </Flex>
      <Flex width={1} flexDirection="column" pl={[2, 0, 3]} pt={[5, 3, 300]}>
        <Box width={[1, 1, 350]} height={['auto', 250, 'auto']}>
          <RoundedImg
            height={['auto', 250, 'auto']}
            fluid={collageImage3.childImageSharp.fluid}
            borderTopLeftRadius={['0px', '0px', '0px']}
            borderBottomLeftRadius="25px"
          />
        </Box>
      </Flex>
    </Flex>
  );
};
