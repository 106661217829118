import * as React from 'react';
import { Flex } from '@westhechiang/flex';
import { StatisticsBlock } from '../../../StatisticsBlock';
import { H2 } from '../../../H2';

export const HomePageStatisticsLeft = () => (
  <Flex
    width={[1, 1, 1]}
    mr={[0, 100, 134]}
    ml={[0, 0, 3]}
    px={[30, 3, 0]}
    flexDirection="column"
    position="relative"
  >
    <StatisticsBlock
      preHeading="Women are often overlooked purchasing powerhouses"
      percentage="84"
      subHeading="of all purchases are made or influenced by women"
      text="and yet"
      textProps={{
        mb: 0,
      }}
    />
    <StatisticsBlock
      percentage="89"
      color="magenta"
      subHeading="of all ads are created or led by men"
      text=""
      borderTop={0}
      mt={0}
    />
    <StatisticsBlock
      preHeading="Gender equality appeals to everyone"
      percentage="75"
      subHeading="of consumers feel more positively toward companies that demonstrate gender equality in their advertising"
      borderTop={1}
    />
  </Flex>
);
