import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Flex } from '@westhechiang/flex';
import { Box } from '@westhechiang/box';
import { RoundedImg } from '../../../../RoundedImg';

export const HomePageCollage2 = () => {
  const {
    collageImage1,
    collageImage2,
    collageImage3,
  } = useStaticQuery(graphql`
    query HomePageCollage2Query {
      collageImage1: file(relativePath: { eq: "girl-with-leaf.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 325, maxHeight: 444) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      collageImage2: file(relativePath: { eq: "dinner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 456, maxHeight: 708) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      collageImage3: file(relativePath: { eq: "girls-laughing.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 456, maxHeight: 372) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Flex
      width={1}
      flexDirection={['column']}
      justifyContent="flex-start"
      alignItems={['flex-start', 'flex-start', 'flex-start']}
      pt={[3, 3, 100]}
    >
      <Flex
        width={1}
        flexDirection={['row', 'column-reverse', 'row']}
        alignItems={['flex-end', 'flex-start', 'flex-end']}
      >
        <Box width={[132, 325, 325]} mr={[2, 3]} mt={[0, 3, 0]}>
          <RoundedImg
            fluid={collageImage1.childImageSharp.fluid}
            borderBottomLeftRadius={['0']}
            borderTopRightRadius="25px"
            borderTopLeftRadius={['0']}
          />
        </Box>
        <Box width={[207, 325, 456]}>
          <RoundedImg
            fluid={collageImage2.childImageSharp.fluid}
            borderBottomRightRadius="25px"
          />
        </Box>
      </Flex>
      <Flex
        width={1}
        flexDirection="column"
        alignItems={['center', 'flex-start', 'center']}
        pl={[2, 0, 3]}
        pt={[2, 3, 3]}
      >
        <Box width={[228, 325, 372]}>
          <RoundedImg
            fluid={collageImage3.childImageSharp.fluid}
            borderTopLeftRadius={['25px']}
            borderBottomRightRadius="25px"
          />
        </Box>
      </Flex>
    </Flex>
  );
};
