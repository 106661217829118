import * as React from 'react';
import { Flex } from '@westhechiang/flex';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { P } from '../../../../../../P';
import { Button } from '../../../../../../Button';
import { PinkHeading } from '../../../../../../Headings/PinkHeading/PinkHeading';
import { TextInput } from '../../../../../../TextInput';
import { Box, useToast } from '@chakra-ui/react';

export const SubscribeBlock = () => {
  const [email, setEmail] = React.useState('');
  const toast = useToast();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const { result, msg } = await addToMailchimp(email, {
        SOURCE: 'newsletter',
      });

      if (result === 'success') {
        toast({
          render: () => (
            <Flex
              px={40}
              py={20}
              alignItems={['center']}
              justifyContent={['center']}
              minWidth={200}
              zIndex={20}
              backgroundColor="magenta"
              borderRadius={5}
              flexDirection="column"
            >
              <P color="white" fontWeight="bold" textAlign="center">
                Thank you for subscribing! 😘
              </P>
            </Flex>
          ),
          duration: 9000,
          isClosable: true,
        });
      }
      if (result === 'error') {
        toast({
          render: () => (
            <Flex
              px={40}
              py={20}
              alignItems={['center']}
              justifyContent={['center']}
              minWidth={500}
              zIndex={20}
              backgroundColor="coral"
              borderRadius={5}
              flexDirection="column"
            >
              <P color="white" fontWeight="bold" mb={2} textAlign="center">
                Uh Oh!
              </P>
              <Box color="white" dangerouslySetInnerHTML={{ __html: msg }} />
            </Flex>
          ),
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (e) {
      console.log('could not send email to mailchimp:', e);
    }
  };

  return (
    <Flex
      alignItems={['flex-start', 'flex-start']}
      flexDirection="column"
      height={['auto', 'auto', 456]}
      justifyContent="center"
      mt={[5, 5, 0]}
      position={['relative']}
      px={[3, 3, 3]}
      mr={[0, 4, 0]}
      width={[1, 1, 0.4]}
      zIndex={4}
    >
      <PinkHeading fontFamily="logo" lineHeight={['48px']} width={[1, 1, 300]}>
        Are you
      </PinkHeading>
      <PinkHeading
        lineHeight={['48px']}
        mb={[3, 0]}
        textAlign={['left']}
        width={[1, 1, 320]}
        fontFamily="logo"
      >
        canna-curious?
      </PinkHeading>
      <P m={0} mb={3}>
        Sign up for our newsletter and we’ll keep you updated on our fave
        brands, products, and events!
      </P>
      <Flex width={1}>
        <TextInput
          borderTop={1}
          borderLeft={1}
          borderBottom={1}
          borderRight={0}
          borderColor="mustard"
          fontFamily="body"
          fontWeight="bold"
          height={48}
          onChange={handleEmailChange}
          placeholder="email"
          placeholderColor="#BCC8D6"
          value={email}
          width={[1, 1, 336]}
        />
        <Button
          onClick={handleSubmit}
          px={3}
          width={'auto'}
          height={48}
          borderBottomLeftRadius={0}
          borderTopLeftRadius={0}
          boxShadow="none"
          textProps={{
            fontSize: 3,
            whiteSpace: 'nowrap',
          }}
        >
          SIGN UP
        </Button>
      </Flex>
    </Flex>
  );
};
