import * as React from 'react';
import { Flex } from '@westhechiang/flex';
import { Box } from '@westhechiang/box';
import { PinkHeading } from '../../../../Headings';

export const BridgeThatGapBlock = () => (
  <Flex
    width={[1]}
    height={[200]}
    py={3}
    justifyContent="center"
    alignItems="center"
  >
    <Box px={[3, 3, 3]}>
      <PinkHeading
        mb={3}
        textAlign={['center']}
        fontSize={[9, 10]}
        fontFamily="logo"
        color="mustard"
        lineHeight="48px"
      >
        We're here to bridge that gap
      </PinkHeading>
    </Box>
  </Flex>
);
