import * as React from 'react';
import { Flex } from '@westhechiang/flex';
import { HomePageStatisticsRight } from './components/HomePageStatisticsRight';
import { HomePageCollage2 } from '../HomePageCollage2';

export const HomePageBlock4 = () => (
  <Flex width={1} flexDirection={['column', 'row', 'row']}>
    <HomePageCollage2 />
    <HomePageStatisticsRight />
  </Flex>
);
