import * as React from 'react';
import { Flex } from '@westhechiang/flex';
import { Text } from '@westhechiang/text';
import { P } from '../../../../P';
import { PurpleHeading } from '../../../../Headings/PurpleHeading/PurpleHeading';

interface StatisticsBlockProps {
  preHeading?: string;
  percentage: string;
  subHeading: string;
  text?: string;
  borderTop: number;
  color: string;
  isFirst?: boolean;
  textProps: object;
}

export const StatisticsBlock = ({
  preHeading,
  percentage,
  subHeading,
  text,
  borderTop,
  isFirst,
  textProps,
  color,
  ...props
}: StatisticsBlockProps) => (
  <Flex
    flexDirection="column"
    width={1}
    mt={[isFirst ? 0 : 2, isFirst ? 0 : 4]}
    borderTop={borderTop}
    borderColor="mustard"
    pt={borderTop === 1 ? 4 : 0}
    {...props}
  >
    {preHeading && <P mb={3}>{preHeading}</P>}
    <Text
      color={color}
      fontFamily="logo"
      fontSize={[64]}
      fontWeight={600}
      m={0}
      mb={2}
    >
      {`${percentage}%`}
    </Text>
    <PurpleHeading
      fontWeight={600}
      fontSize={[8, 5, 7]}
      lineHeight="34px"
      color={color}
      mb={3}
    >
      {subHeading}
    </PurpleHeading>
    {text && (
      <Flex width={1} alignItems="center" justifyContent="center" my={3}>
        <Flex width={1} height={1} bg="mustard" mr={3} />
        <P whiteSpace="nowrap" {...textProps}>
          {text}
        </P>
        <Flex width={1} height={1} bg="mustard" ml={3} />
      </Flex>
    )}
  </Flex>
);

StatisticsBlock.defaultProps = {
  isFirst: false,
  color: 'purple',
};
