import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import { CannabisPage } from '../components/Pages/CannabisPage';

export const Cannabis = () => {
  return (
    <Layout>
      <CannabisPage />
    </Layout>
  );
};

export default Cannabis;

export const pageQuery = graphql`
  query CannabisQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
