import * as React from 'react';
import { Flex } from '@westhechiang/flex';
import { HomePageStatisticsLeft } from './components/HomePageStatisticsLeft';
import { HomePageCollage1 } from '../HomePageCollage1';

export const HomePageBlock3 = () => (
  <Flex width={1} flexDirection={['column-reverse', 'row', 'row']}>
    <HomePageStatisticsLeft />
    <HomePageCollage1 />
  </Flex>
);
