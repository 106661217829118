import React from 'react';
import { Flex } from '@westhechiang/flex';
import { P } from '../../../../P';
import { PurpleHeading } from '../../../../Headings/PurpleHeading/PurpleHeading';
import { ShowModalButton } from '../../../../Modal';
import { SubscribeBlock } from '../HomePageBlock5/components/SubscribeBlock';

export const WhatDoWomenWantSection = () => (
  <Flex
    flexDirection="column"
    position="relative"
    px={[3, 3, 0]}
    width={[1, 1, 0.65]}
    my={5}
  >
    <Flex
      flexDirection="column"
      alignItems="center"
      mt={[100, 100, 40]}
      width={1}
    >
      <PurpleHeading fontSize={10} textAlign="center" fontFamily="logo">
        So what do women want?
      </PurpleHeading>
      <Flex justifyContent="center">
        <P
          textAlign="center"
          m={0}
          mt="18px"
          mb="24px"
          width={[1, '400px', '650px']}
        >
          When it comes to cannabis, they want specific solutions targeted to
          their needs. They want reliability, transparency, and companies that
          align with their core beliefs. In short, they want products that are
          more than just a pretty package.
        </P>
      </Flex>
    </Flex>
  </Flex>
);
